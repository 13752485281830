<script setup>
  const props = defineProps({
    photoSize: Number,
    profile: { required: true, type: Object },
    row: Boolean,
    small: Boolean,
    titleSize: {
      type: String,
      default: 'lg'
    }
  })

  const photoSize = props.photoSize ?? (props.small ? 20 : 32)
</script>

<template>
  <div
    class="flex flex-col items-center justify-center space-y-2"
    :class="{
      'sm:flex-row sm:space-x-4 sm:space-y-0': row
    }"
  >
    <div class="relative">
      <div
        class="rounded-full bg-zinc-300 bg-cover bg-center"
        :class="`h-${photoSize} w-${photoSize}`"
        :style="{
          backgroundImage: profile.photo ? `url('${profile.photo}')` : undefined
        }"
      />
      <NuxtLink
        class="absolute flex"
        v-if="profile.linkedin"
        :class="small ? 'bottom-0 right-0' : 'bottom-1 right-1'"
        target="_blank"
        :to="`https://www.linkedin.com/${
          profile.linkedin.startsWith('in/') || profile.linkedin.startsWith('company/') ? profile.linkedin : 'in/' + profile.linkedin
        }`"
      >
        <FontAwesomeLayers :class="small ? 'text-lg' : 'text-2xl'">
          <FontAwesomeIcon class="text-linkedin" icon="fa-solid fa-square" />
          <FontAwesomeIcon class="text-white" icon="fa-brands fa-linkedin-in" transform="shrink-6" />
        </FontAwesomeLayers>
      </NuxtLink>
    </div>
    <div
      class="text-center text-lg font-bold"
      v-if="profile.name || profile.title"
      :class="{
        'sm:text-left': row
      }"
    >
      <div class="font-special capitalize text-viking-400" v-if="profile.name" v-text="profile.name" />
      <div v-if="profile.title" v-text="profile.title" :class="`text-${titleSize}`" />
    </div>
  </div>
</template>
